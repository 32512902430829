<template>
  <v-list-item @click="listItemClicked">
    <v-list-item-title>Report an Issue</v-list-item-title>
    <v-dialog
      v-model="display"
      max-width="900"
      persistent
    >
      <v-card
        :style="`max-height: ${
          this.$vuetify.breakpoint.height - 150 + 'px'
        }; overflow: hidden;`"
        elevation="5"
        class="mt-0 pt-0"
      >
        <v-row align="start">
          <v-col>
            <!-- Base Card Title -->
            <v-card-title class="v-dialogHeader text-left px-4 py-1">
              Report a FieldVue Issue
            </v-card-title>
            <v-form
              ref="issueForm"
              class="px-0"
            >
              <!-- Main Form Card -->
              <v-card
                elevation="0"
                style="overflow-x: hidden; overflow-y: auto"
                :max-height="`${this.$vuetify.breakpoint.height - 275 + 'px'}`"
              >
                <!-- General Information -->
                <v-card
                  class="pa-4 mb-0"
                  elevation="0"
                >
                  <v-card-title class="text-left pa-0 mb-4">
                    General Information
                  </v-card-title>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Submitter -->
                      <v-text-field
                        v-model="submitter"
                        label="Submitter"
                        :rules="submitterRules"
                        :height="fieldHeight"
                        dense
                        readonly
                        required
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Crew ID -->
                      <v-text-field
                        v-model="crewId"
                        label="Crew ID"
                        maxlength="12"
                        :height="fieldHeight"
                        dense
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Device type -->
                      <v-select
                        v-model="deviceType"
                        :items="deviceTypes"
                        label="Device Type*"
                        :rules="deviceTypeRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('deviceTypeRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Phone Number -->
                      <v-text-field
                        v-model="phoneNumber"
                        v-maska
                        data-maska="(###) ###-####"
                        label="Phone Number*"
                        :rules="phoneNumberRules"
                        :height="fieldHeight"
                        maxlength="15"
                        dense
                        required
                        @blur="setFormRules('phoneNumberRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="deviceType === 'Other'">
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Other Device Type -->
                      <v-text-field
                        v-model="otherDevice"
                        label="Other Device*"
                        :height="fieldHeight"
                        :rules="otherDeviceRules"
                        maxlength="50"
                        required
                        dense
                        @blur="setFormRules('otherDeviceRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Internet Connection Type -->
                      <v-select
                        v-model="internetType"
                        :items="internetTypes"
                        :label="
                          platform !== 'mobile'
                            ? 'Internet Connection Type*'
                            : 'Internet Type*'
                        "
                        :rules="internetTypeRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('internetTypeRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Team Viewer Number -->
                      <v-text-field
                        v-model="teamViewerNumber"
                        label="Team Viewer Number"
                        maxlength="20"
                        :height="fieldHeight"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <!-- Issue Details -->
                <v-card
                  class="pa-4 mt-0"
                  elevation="0"
                >
                  <v-card-title class="text-left pa-0 mb-4">
                    Issue Details
                  </v-card-title>
                  <v-row>
                    <v-col
                      :cols="mobileCols"
                      class="pb-0"
                    >
                      <!-- What feature is the issue for? -->
                      <v-select
                        v-model="feature"
                        :items="features"
                        item-text="name"
                        label="What feature is the issue for?*"
                        :rules="featureRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('featureRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      feature === 'QC Inspection App' && platform !== 'mobile'
                    "
                  >
                    <v-col
                      cols="4"
                      class="pb-0"
                    >
                      <!-- Customer Name -->
                      <v-text-field
                        v-model="customerName"
                        label="Customer Name*"
                        :rules="customerNameRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('customerNameRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="pb-0"
                    >
                      <!-- DCT Project -->
                      <v-text-field
                        v-model="dctProject"
                        label="DCT Project*"
                        :rules="dctProjectRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('dctProjectRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="pb-0"
                    >
                      <!-- QC Model -->
                      <v-text-field
                        v-model="qcModel"
                        label="QC Model*"
                        :rules="qcModelRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('qcModelRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <div
                    v-if="
                      feature === 'QC Inspection App' && platform === 'mobile'
                    "
                  >
                    <v-row>
                      <v-col class="pb-0">
                        <!-- Customer Name -->
                        <v-text-field
                          v-model="customerName"
                          label="Customer Name*"
                          :rules="customerNameRules"
                          :height="fieldHeight"
                          dense
                          required
                          @blur="setFormRules('customerNameRules')"
                          @change="toggleSubmitButton"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <!-- DCT Project -->
                        <v-text-field
                          v-model="dctProject"
                          label="DCT Project*"
                          :rules="dctProjectRules"
                          :height="fieldHeight"
                          dense
                          required
                          @blur="setFormRules('dctProjectRules')"
                          @change="toggleSubmitButton"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <!-- QC Model -->
                        <v-text-field
                          v-model="qcModel"
                          label="QC Model*"
                          :rules="qcModelRules"
                          :height="fieldHeight"
                          dense
                          required
                          @blur="setFormRules('qcModelRules')"
                          @change="toggleSubmitButton"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-if="feature === 'Safety Audit App'">
                    <v-col
                      :cols="mobileCols"
                      class="pb-0"
                    >
                      <!-- SA Model -->
                      <v-text-field
                        v-model="saModel"
                        label="SA Model*"
                        :rules="saModelRules"
                        :height="fieldHeight"
                        dense
                        required
                        @blur="setFormRules('saModelRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="feature === 'Other'">
                    <v-col
                      :cols="mobileCols"
                      class="pb-0"
                    >
                      <!-- Other Function -->
                      <v-text-field
                        v-model="otherFeature"
                        label="Describe Other Feature*"
                        :rules="otherFeatureRules"
                        :height="fieldHeight"
                        maxlength="100"
                        dense
                        required
                        @blur="setFormRules('otherFeatureRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <!-- Short Description of Issue -->
                      <v-textarea
                        v-model="issueDescription"
                        label="Short Description of Issue*"
                        :rules="issueDescriptionRules"
                        maxlength="250"
                        row-height="10"
                        auto-grow
                        counter
                        dense
                        required
                        @blur="setFormRules('issueDescriptionRules')"
                        @change="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <!-- Additional Information -->
                      <v-textarea
                        v-model="additionalInformation"
                        label="Additional Information"
                        row-height="10"
                        maxlength="250"
                        auto-grow
                        counter
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="mobileCols"
                      class="pb-0"
                    >
                      <template>
                        <!-- File Attachment -->
                        <v-file-input
                          v-model="fileAttachment"
                          :height="fieldHeight"
                          accept=".jpeg,.jpg,.png,.txt,.doc,.docx,.xls,.xlsx,.pptx,.pdf,.csv"
                          multiple
                          dense
                          label="Attach Files"
                          :clearable="false"
                          @change="fileChanged"
                        >
                          <template #selection="{ index, text }">
                            <v-chip
                              v-if="index < fileAttachmentMaxLength"
                              label
                              close
                              color="accent"
                              small
                              @click:close="deleteFile(index)"
                            >
                              {{ text }}
                            </v-chip>
                            <span
                              v-else-if="index === fileAttachmentMaxLength"
                              class="
                                text-overline
                                grey--text
                                text--darken-3
                                mx-2
                              "
                            >
                              +{{
                                fileAttachment.length - fileAttachmentMaxLength
                              }}
                              File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </template>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
              <v-divider />
              <!-- Form Actions -->
              <v-card-actions
                class="pr-2 py-3"
              >
                <v-spacer />
                <v-btn
                  color="accent"
                  :disabled="submitButtonDisabled"
                  @click="submit"
                >
                  Submit
                </v-btn>
                <v-snackbar
                  v-model="snackbar"
                  :app="false"
                  :timeout="snackbarTimeout"
                  absolute
                  centered
                  light
                  :multi-line="snackbarMultiline"
                  rounded
                >
                  <span class="text-h4">
                    {{ snackbarText }}
                  </span>
                  <template #action="{ attrs }">
                    <v-btn
                      color="blue"
                      text
                      v-bind="attrs"
                      @click="closeSnackbar"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
                <!-- Cancel button + dialog -->
                <v-dialog
                  v-model="cancelDialog"
                  persistent
                  max-width="300"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Cancel
                    </v-btn>
                  </template>
                  <v-card class="text-left">
                    <v-card-title
                      class="v-dialogHeader text-left px-3 py-1"
                    >
                      Cancel?
                    </v-card-title>
                    <v-card-text
                      class="px-3 py-1"
                    >
                      Are you sure you want to cancel?
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="accent"
                        @click="cancel"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="cancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import { vMaska } from 'maska'
import ReportAnIssueManager from '../../Lib/ReportAnIssueManager'

export default {
  name: 'ReportAnIssue',
  directives: { maska: vMaska },
  props: {
    platform: String,
  },
  data () {
    return {
      additionalInformation: '',
      allFiles: [],
      allRules: {
        customerNameRules: [(v) => !!v || 'Customer Name is required'],
        dctProjectRules: [(v) => !!v || 'DCT Project is required'],
        deviceTypeRules: [(v) => !!v || 'Device Type is required'],
        featureRules: [(v) => !!v || 'Feature is required'],
        internetTypeRules: [
          (v) => !!v || 'Internet Connection Type is required',
        ],
        issueDescriptionRules: [(v) => !!v || 'Description is required'],
        otherDeviceRules: [(v) => !!v || 'Device Type is required'],
        otherFeatureRules: [(v) => !!v || 'Description is required'],
        phoneNumberRules: [
          (v) => !!v || 'Phone Number is required',
          (v) => v.length >= 10 || 'Phone Number must be 10 digits',
          // Regex from https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
          // Validates several phone number formats
          (v) =>
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) ||
            'Invalid Phone Number',
        ],
        qcModelRules: [(v) => !!v || 'QC Model is required'],
        saModelRules: [(v) => !!v || 'SA Model is required'],
        submitterRules: [(v) => !!v || 'Submitter is required'],
      },
      cancelDialog: false,
      configurationItems: {
        FieldVue: '40de8e3bdb4a7850f7547e77f4961942',
        FMT: '25c9e3621bb9d094ffe043f8bc4bcb4f',
        QC: 'defbee874f9a1bc08dbb433e0210c749',
        SA: '5e0cae874f9a1bc08dbb433e0210c7bf',
      },
      crewId: '',
      customerName: '',
      customerNameRules: [],
      dctProject: '',
      dctProjectRules: [],
      deviceType: '',
      deviceTypes: [
        'Cell Phone',
        'Dell Tablet',
        'GETAC Tablet',
        'Laptop',
        'Microsoft Surface Tablet',
        'Panasonic Rugged',
        'Trimble Rugged',
        'Other',
      ],
      deviceTypeRules: [],
      display: false,
      employeeId: this.$store.getters.employeeId,
      feature: '',
      features: [
        'Application Access',
        'Company News',
        'Daily Briefing Report',
        'Daily Counts Report',
        'DCT PONI Collection Status',
        'DOT Failures',
        'DOT Report',
        'Field Inventory',
        'Fulcrum Field Data',
        'Fulcrum PONI Collection Status',
        'Fulcrum Running Man',
        'Ground Wire DOPs',
        'Incident Reporting',
        'Materials & Supplies Orders',
        'My Approvals',
        'My Open Items',
        'My Tasks',
        'My Team',
        'New EE Checklists',
        'Notifications',
        'Orders',
        'PACE Topic',
        'Passport',
        'Payroll',
        'Personnel Action Requests',
        'Photo Review Data',
        'PONI Photo Review',
        'Project Reporting',
        'QC Inspection App',
        'QC Inspections',
        'Qlik',
        'Quality Topic',
        'Remaining Audits',
        'Remaining QCs',
        'Report Vehicle Issue',
        'Safety Audit App',
        'Safety Audits',
        'Safety Tip',
        'Task Assignment',
        'Task Status',
        'Vehicle Assignments',
        'Vehicle Issues',
        'Vehicles',
        'Other',
      ],
      featureRules: [],
      fieldHeight: '20',
      fileAttachment: [],
      fileAttachmentBase64: [],
      internetType: '',
      internetTypes: ['Cellular', 'WiFi', 'Wired'],
      internetTypeRules: [],
      isFormValid: false,
      issueDescription: '',
      issueDescriptionRules: [],
      location: null,
      otherDevice: '',
      otherDeviceRules: [],
      otherFeature: '',
      otherFeatureRules: [],
      phoneNumber: '',
      phoneNumberRules: [],
      preventFileChange: false,
      qcModel: '',
      qcModelRules: [],
      saModel: '',
      saModelRules: [],
      submitButtonDisabled: true,
      submitter: this.$store.getters.userName,
      submitterRules: [],
      snackbar: false,
      snackbarClosesDialog: false,
      snackbarMultiline: true,
      snackbarText: null,
      snackbarTimeout: 6000,
      teamViewerNumber: '',
    }
  },
  computed: {
    configurationTable () {
      return {
        'Application Access': this.configurationItems.FieldVue,
        'Company News': this.configurationItems.FieldVue,
        'Daily Counts Report': this.configurationItems.FieldVue,
        'Daily Briefing Report': this.configurationItems.FieldVue,
        'DCT PONI Collection Status': this.configurationItems.FieldVue,
        'DOT Report': this.configurationItems.FieldVue,
        'DOT Failures': this.configurationItems.FieldVue,
        'Field Inventory': this.configurationItems.FMT,
        'Fulcrum Field Data': this.configurationItems.FieldVue,
        'Fulcrum PONI Collection Status': this.configurationItems.FieldVue,
        'Fulcrum Running Man': this.configurationItems.FieldVue,
        'Ground Wire DOPs': this.configurationItems.FieldVue,
        'Incident Reporting': this.configurationItems.FMT,
        'Materials & Supplies Orders': this.configurationItems.FMT,
        'My Approvals': this.configurationItems.FMT,
        'My Open Items': this.configurationItems.FieldVue,
        'My Tasks': this.configurationItems.FieldVue,
        'My Team': this.configurationItems.FieldVue,
        'New EE Checklists': this.configurationItems.FieldVue,
        Notifications: this.configurationItems.FieldVue,
        Orders: this.configurationItems.FMT,
        'PACE Topic': this.configurationItems.FieldVue,
        Passport: this.configurationItems.FieldVue,
        Payroll: this.configurationItems.FMT,
        'Personnel Action Requests': this.configurationItems.FMT,
        'Photo Review Data': this.configurationItems.FieldVue,
        'PONI Photo Review': this.configurationItems.FieldVue,
        'Project Reporting': this.configurationItems.FieldVue,
        'QC Inspection App': this.configurationItems.QC,
        'QC Inspections': this.configurationItems.FieldVue,
        Qlik: this.configurationItems.FieldVue,
        'Quality Topic': this.configurationItems.FieldVue,
        'Remaining Audits': this.configurationItems.FieldVue,
        'Remaining QCs': this.configurationItems.FieldVue,
        'Report Vehicle Issue': this.configurationItems.FieldVue,
        'Safety Audit App': this.configurationItems.SA,
        'Safety Audits': this.configurationItems.FieldVue,
        'Safety Tip': this.configurationItems.FieldVue,
        'Task Assignment': this.configurationItems.FieldVue,
        'Task Status': this.configurationItems.FieldVue,
        'Vehicle Assignments': this.configurationItems.FieldVue,
        'Vehicle Issues': this.configurationItems.FieldVue,
        Vehicles: this.configurationItems.FieldVue,
        Other: this.configurationItems.FieldVue,
      }
    },
    fileAttachmentMaxLength () {
      return this.platform !== 'mobile' ? 2 : 1
    },
    mobileCols () {
      return this.platform !== 'mobile' ? 6 : 12
    },
  },
  watch: {
    display (value) {
      if (value) {
        this.$emit('close-menu')
      }
    },
  },
  mounted () {
    // Gets the users location if they allow it
    navigator.geolocation.getCurrentPosition((loc) => {
      this.location = loc
    })
    // Preloads form items from the users' previous submission
    if (this.$storage.has('reportAnIssueDataSource')) {
      const dataSource = this.$storage.get('reportAnIssueDataSource')
      this.deviceType = dataSource.deviceType
      this.otherDevice = dataSource.otherDevice
      this.phoneNumber = dataSource.phoneNumber
      this.teamViewerNumber = dataSource.teamViewerNumber
      this.crewId = dataSource.crewId
    }
  },
  methods: {
    // Clears the form and closes both dialogs (cancel and main form)
    cancel () {
      this.clearForm()
      this.cancelDialog = false
      this.display = false
    },
    // Sets all form items and their rules back to empty values
    // Then validates the form to clear any previous errors
    clearForm () {
      this.additionalInformation = ''
      this.allFiles = []
      this.customerName = ''
      this.customerNameRules = []
      this.dctProject = ''
      this.dctProjectRules = []
      this.feature = ''
      this.featureRules = []
      this.fileAttachment = []
      this.internetType = ''
      this.internetTypeRules = []
      this.issueDescription = ''
      this.issueDescriptionRules = []
      this.otherFeature = ''
      this.otherFeatureRules = []
      this.qcModel = ''
      this.qcModelRules = []
      this.saModel = ''
      this.saModelRules = []
      this.validate()
    },
    // Closes the snackbar
    // Also closes the main form dialog after a valid submission
    closeSnackbar () {
      this.snackbar = false
      if (this.snackbarClosesDialog) {
        this.display = false
      }
    },
    // Creates an object to send to ServiceNow from the data variables in the form
    createIncident () {
      const self = this
      const device =
        self.deviceType !== 'Other' ? self.deviceType : self.otherDevice
      return {
        submitter: self.submitter,
        employeeId: self.employeeId,
        deviceType: device,
        internetConnectionType: self.internetType,
        crewId: self.crewId,
        phoneNumber: self.phoneNumber,
        teamViewerNumber: self.teamViewerNumber,
        appFunction: self.feature,
        otherFunction: self.otherFeature,
        shortdescription: self.issueDescription,
        additionalInformation: self.additionalInformation,
        customerName: self.customerName,
        dctProject: self.dctProject,
        saModel: self.saModel,
        qcModel: self.qcModel,
        attachmentList: self.fileAttachmentBase64,
        latitude: self.location && self.location.coords.latitude !== null ? self.location.coords.latitude : '',
        longitude: self.location && self.location.coords.longitude !== null ? self.location.coords.longitude : '',
        configurationItem: self.configurationTable[self.feature],
      }
    },
    // Removes a specific file from the array
    deleteFile (index) {
      this.fileAttachment.splice(index, 1)
    },
    // Called whenever this.fileAttachment is changed
    fileChanged () {
      // this.preventFileChange prevents the change event from being fired an extra time
      if (this.preventFileChange) {
        this.preventFileChange = false
      } else {
        this.allFiles = this.fileAttachment.concat(this.allFiles)
        // This assignment re-fires the change event
        // So we prevent that change by resetting the flag
        this.fileAttachment = this.allFiles
        this.fileAttachment.forEach((file, index) => {
          this.toBase64(file).then((res) => {
            this.fileAttachmentBase64[index] = {
              fileName: this.fileAttachment[index].name,
              contentType: this.getContentType(index),
              payload: res,
            }
          })
        })
        this.preventFileChange = true
      }
    },
    // Reformats .txt documents to the proper type for ServiceNow
    getContentType (index) {
      // Changes content type for .txt files to ServiceNow accepted type
      if (this.fileAttachment[index].type === 'text/plain') {
        return 'text/xml'
      }
      if (
        this.fileAttachment[index].type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        return 'application/vnd.openxmlformats-officedoc'
      }
      return this.fileAttachment[index].type
    },
    // Logs navigation to the Report a FieldVue Issue form
    // Called when the list item "Report an Issue" is clicked in the Support menu
    listItemClicked () {
      setTimeout(() => {
        this.display = true
      })
    },
    // Saves certain values to localStorage to be preloaded into the form
    // Form is validated before this gets called so no need to pre-check the values
    saveValuesToLocalStorage () {
      const reportAnIssueDataSource = {
        deviceType: this.deviceType,
        otherDevice: this.otherDevice,
        phoneNumber: this.phoneNumber,
        teamViewerNumber: this.teamViewerNumber,
        crewId: this.crewId,
      }
      this.$storage.set('reportAnIssueDataSource', reportAnIssueDataSource, {
        compressData: true,
      })
    },
    // Sets the rules of a specified form item
    // Rules are set when an item is blurred or the form is submitted
    // This prevents the form from throwing errors while the user is entering a value
    setFormRules (fieldName) {
      if (fieldName === 'all') {
        Object.keys(this.allRules).forEach((key) => {
          this[key] = this.allRules[key]
        })
      } else {
        this[fieldName] = this.allRules[fieldName]
      }
    },
    // Validates the form and sends the issue to Service Now
    submit () {
      // Sets rules for all form items
      // Ensures all items are validated even if the user never clicked on the field
      this.setFormRules('all')
      // Need to wait for rules to update before validation can occur
      this.$nextTick(() => {
        this.validate()
        // If all fields pass validation
        if (this.isFormValid) {
          // Save certain values to local storage to be pre-loaded into the form
          this.saveValuesToLocalStorage()
          // Create incident and send to SN
          const incident = this.createIncident()
          ReportAnIssueManager.postIncident(incident)
            .then((res) => {
              if (res) {
                // Display success message
                this.snackbarText =
                  'Your issue has been submitted. The Application Support Team will be contacting you shortly.'
                this.snackbarTimeout = 0
                this.snackbarClosesDialog = true
                this.snackbar = true
                // Clear form
                this.clearForm()
                this.writeToLogging(incident)
              } else {
                this.snackbarText = 'There was a problem submitting your issue. Please try again in a moment.'
                this.snackbarTimeout = 6000
                this.snackbarClosesDialog = false
                this.snackbar = true
              }
            })
        } else {
          // Display validation failure message
          this.snackbarText =
            'Form validation failed. Please fix any errors and try again.'
          this.snackbarTimeout = 6000
          this.snackbarClosesDialog = false
          this.snackbar = true
        }
      })
    },
    writeToLogging (incident) {
      this.$azureLogger.writeLogItem('activity', {
        Application: 'Support',
        FeatureName: 'Report an Issue',
        ActivityType: this.$store.getters.loggingActivityType.clicked,
        AdditionalDetails: `
          submitter: ${incident.submitter}
          employeeId: ${incident.employeeId}
          deviceType: ${incident.deviceType}
          internetConnectionType: ${incident.internetConnectionType}
          crewId: ${incident.crewId}
          phoneNumber: ${incident.phoneNumber}
          teamViewerNumber: ${incident.teamViewerNumber}
          appFunction: ${incident.appFunction}
          otherFunction: ${incident.otherFunction}
          shortdescription: ${incident.shortdescription}
          additionalInformation: ${incident.additionalInformation}
          customerName: ${incident.customerName}
          dctProject: ${incident.dctProject}
          saModel: ${incident.saModel}
          qcModel: ${incident.qcModel}
          latitude: ${incident.latitude}
          longitude: ${incident.longitude}
        `,
      })
    },
    // Converts a file into a Base64 string to send to ServiceNow
    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    // Enables the submit button only when all required fields are valid
    toggleSubmitButton () {
      // Guaranteed requirements
      if (
        this.submitter !== '' &&
        this.deviceType !== '' &&
        this.phoneNumber.trim() !== '' &&
        this.internetType !== '' &&
        this.feature !== '' &&
        this.issueDescription.trim() !== ''
      ) {
        this.submitButtonDisabled = false
        // Conditional requirements
        if (this.deviceType === 'Other' && this.otherDevice.trim() === '') {
          this.submitButtonDisabled = true
        }
        if (
          this.feature === 'QC Inspection App' &&
          (this.customerName.trim() === '' ||
            this.dctProject.trim() === '' ||
            this.qcModel.trim() === '')
        ) {
          this.submitButtonDisabled = true
        }
        if (this.feature === 'Safety Audit App' && this.saModel.trim() === '') {
          this.submitButtonDisabled = true
        }
        if (this.feature === 'Other' && this.otherFeature === '') {
          this.submitButtonDisabled = true
        }
      } else {
        this.submitButtonDisabled = true
      }
    },
    // Use v-form api to validate all fields based on given rules
    validate () {
      this.isFormValid = this.$refs.issueForm.validate()
    },
  },
}
</script>

<style scoped>
/* Gives chips the same font attributes as buttons */
.v-chip__content {
  font-size: 0.85rem;
  font-weight: 400;
}
</style>
