import Axios from 'axios'
import Store from '@/store/store'
import DB from '@/db/db'
import { v4 as uuidv4 } from 'uuid'
import { md5 } from 'hash-wasm'
import { referenceDataMixin } from '@/mixins/referenceDataMixin'
import { DateTime } from 'luxon'
import SnakManager from '@/Lib/SnakManager'

// ***********************************************************************************************
// This will perform CRUD operations on the users task form data
// and upload any data to Servicenow which needs to be
export const submitTaskDataWorker = async (params) => {
  let step = 0
  try {
    // turn on spinner semphore
    Store.dispatch('updateTaskSyncExecuting', true)
    Store.dispatch('updateTasksSynced', false)
    // turn on sync badge
    await updateSyncBadge()

    // ***********************************************************************************************
    // process deleted form tasks and attachments
    // get the tasks data where Submitted == 2
    const deletedFormData = await getDeletedFromIDB('Form')
    // get the attachment data where Submitted == 2
    const deletedAttachmentData = await getDeletedFromIDB('Attachment')
    // for each task - send the data to ServiceNow
    for (const data of deletedFormData) {
      step = 1
      if (data.SnId) {
        await sendTaskFormDataToSN('delete', data.SnId, data.TaskDefinitionId, 'Form')
        Store.$app.$azureLogger.writeLogItem('activity', {
          Application: 'Task Data Worker',
          FeatureName: 'updateSyncBadge',
          ActivityType: Store.getters.loggingActivityType.deleted,
          AdditionalDetails: `${data.SnId} was deleted`,
        })
      }
      // remove the record in indexedDb
      await deleteDataInIDB('Form', data.Id)
      await updateSyncBadge()
    }

    for (const data of deletedAttachmentData) {
      step = 1
      if (data.SnId) {
        await sendTaskFormDataToSN('delete', data.SnId, data.TaskDefinitionId, 'Attachment')
      }
      // remove the record in indexedDb
      await deleteDataInIDB('Attachment', data.Id)
      await updateSyncBadge()
    }

    // ***********************************************************************************************
    // process new form tasks and attachments
    // get the tasks that need to be sent to ServiceNow for the first time
    const unSubmittedformData = await getUnsubmittedFromIDB('Form')
    // get the attachments that need to be sent to ServiceNow for the first time
    const unSubmittedAttachmentData = await getUnsubmittedFromIDB('Attachment')
    // for each task form - send the form data to ServiceNow
    for (const data of unSubmittedformData) {
      step = 2
      const newSnId = await sendTaskFormDataToSN('create', data, data.TaskDefinitionId, 'Form')
      if (newSnId) {
        // update the record in indexedDb
        await updateFormDataInIDB(data.Id, newSnId, 'Form', data.Status)
      }
      // update the unsubmittedForms array for the SyncBadge in the AppBar
      await getUnsubmittedFromIDB('Form')
      await updateSyncBadge()
    }

    // for each attachment - send the attachment data to ServiceNow
    for (const data of unSubmittedAttachmentData) {
      step = 2
      let parentSnId = ''
      const parentFormData = await DB.Form.where('Id').equals(data.ParentId).first()
      if (parentFormData) {
        parentSnId = parentFormData.SnId
      }
      const newSnId = await sendTaskFormDataToSN('create', data, data.TaskDefinitionId, 'Attachment', parentSnId)
      if (newSnId) {
        // update the record in indexedDb
        await updateFormDataInIDB(data.Id, newSnId, 'Attachment', data.Status)
      }
      await updateSyncBadge()
    }

    // ***********************************************************************************************
    // process updated form tasks
    // get the tasks data where FormIsDirty == true
    const updatedFormData = await getUpdatedFormsFromIDB()
    // for each task - send the form data to ServiceNow
    for (const data of updatedFormData) {
      step = 3
      await sendTaskFormDataToSN('update', data, data.TaskDefinitionId, 'Form')
      // update the record in indexedDb
      await updateFormDataInIDB(data.Id, data.SnId, 'Form', data.Status)
      await updateSyncBadge()
    }

    // turn off spinner semaphore
    Store.dispatch('updateTaskSyncExecuting', false)
    Store.dispatch('updateTasksSynced', true)
  } catch (err) {
    let errDescription = ''
    switch (step) {
      case 0:
        errDescription = 'A problem occured during task sync. - '
        break
      case 1:
        errDescription = 'A problem occured trying to sync deleted task data. - '
        break
      case 2:
        errDescription = 'A problem occured trying to sync unsubmitted task data. - '
        break
      case 3:
        errDescription = 'A problem occured trying to sync updated task data. - '
        break
    }

    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'taskData web worker',
      FeatureName: 'Syncing tasks to ServiceNow',
      ErrorCode: errDescription + err.name,
      ErrorDescription: 'Failed to sync task data.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })

    // turn off spinner semaphore
    Store.dispatch('updateTaskSyncExecuting', false)
  }
}

// ***********************************************************************************************
// This will remove any task form data outside the current three week period, then
// download the task form data from Servicenow for the user
export const syncTaskDataWorker = async (employeeId) => {
  // turn on spinner semphore
  Store.dispatch('updateTaskSyncExecuting', true)
  Store.dispatch('updateTasksSynced', false)
  const dateRange = getBeginAndEndDateRange()
  // remove old tasks from IDB
  await cleanupIDB(dateRange.startDate)
  // sync task definitions
  await syncTask(employeeId, dateRange)

  // turn off spinner semphore
  Store.dispatch('updateTaskSyncExecuting', false)
  Store.dispatch('updateTasksSynced', true)
}

// ***********************************************************************************************
// this is used to remove tasks which we created during an impersonation session.
// the value of employeeId represents the user that was impersonated
export const removeImpersonatedTasksDataWorker = async (employeeId) => {
  if (employeeId) {
    const taskForms = await DB.Form.where('EmployeeId').equals(employeeId.trim()).toArray()
    if (taskForms.length > 0) {
      await DB.Form.where('EmployeeId').equals(employeeId.trim()).delete()
      for (const formData of taskForms) {
        await DB.Attachment.where('ParentId').equals(formData.Id).delete()
      }
    }
  } else {
    // remove everything
    await DB.Attachment.clear()
    await DB.Form.clear()
  }
}

// ***********************************************************************************************
// this is used to update the arrays tracked by the SyncBadge in the AppBar
// keeps track of what data exists in IDB that still needs to be sent to SN
export const updateSyncBadge = async () => {
  await getUpdatedFormsFromIDB()
  await getUnsubmittedFromIDB('Attachment')
  await getDeletedFromIDB('Form')
  await getDeletedFromIDB('Attachment')
}

// ***********************************************************************************************
// this method pulls any tasks for the user within the date range specified (aka last two weeks)
async function syncTask (employeeId, dateRange) {
  try {
    // turn on spinner semphore
    Store.dispatch('updateTaskSyncExecuting', true)
    const requestData = {
      employeeId: employeeId,
      fvTaskIds: [],
    }
    const taskDefinitions = await referenceDataMixin.methods.getTaskDefinitions()
    // loop through each task definition and create the payload
    const tasks = []
    for (const taskDefinition of taskDefinitions) {
      const fvTaskId = taskDefinition.Id
      tasks.push({
        fvTaskId: fvTaskId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      })
    }
    // eslint-disable-next-line
    const uniqueTasks = [...new Map(tasks.map(item => [item['fvTaskId'], item])).values()]
    requestData.fvTaskIds = uniqueTasks
    // get the tasks from ServiceNow based on the date range and user's employeeId
    const formData = await getTaskFormDataFromSN('get', requestData, 'Form')
    // store their ID in an array to be used when deleting tasks from IDB that aren't on SN
    const taskIds = []
    // loop through the different task types
    for (const form of formData) {
      // loop through the tasks (aka form data) within the task type
      for (const data of form[Object.keys(form)[0]]) {
        // we're not importing formData created in ServiceNow
        if (data.fvId) {
          taskIds.push(data.taskId)
          // see if a matching task exists in IDB
          // const idbTaskRecord = await DB.Form.where({ SnId: data.taskId }).first()
          const idbTaskRecord = await DB.Form.where('Id').equals(data.fvId).or('SnId').equals(data.taskId).first()
          if (idbTaskRecord) {
            // is the local data older/same and not dirty?  these we can update.
            if (DateTime.fromFormat(idbTaskRecord.ModifiedOn, 'yyyy-MM-dd hh:mm:ss a').ts <= DateTime.fromFormat(data.dateSaved, 'yyyy-MM-dd hh:mm:ss a').ts && idbTaskRecord.FormIsDirty === 'false') {
              await insertUpdateIDBTaskRecord(data, employeeId)
            }
          } else {
            // task not found, so add it to IDB
            await insertUpdateIDBTaskRecord(data, employeeId)
          }

          // get any attachments that go with the task data
          // is there an attachment property?
          if ('attachments' in data) {
            // loop through the attachments
            for (const attachment of data.attachments) {
              // get the attachment from ServiceNow
              const snId = attachment.snId // <-- the ServiceNow sysId for the image
              const attachmentFromSN = await getAttachmentFromSN('get', snId, 'Attachment')
              // insert the attachment into IDB
              if (attachmentFromSN) {
                // the image's ServiceNow sysId is not returned from the call to ServiceNow, so pass it in
                await insertUpdateIDBAttachmentRecord(attachmentFromSN, snId, data.fvId)
              }
            }
          }
        } else {
          taskIds.push(data.taskId)
          // Cannot save multiple tasks with empty fvId so we have to use the taskId (FMT#) to store it in IDB
          data.fvId = data.taskId
          await insertUpdateIDBTaskRecord(data, employeeId)
        }
      }
    }
    // loop through IDB tasks and delete any that are not on SN
    const idbTasks = await DB.Form.toArray()
    for (const task of idbTasks) {
      if (taskIds && taskIds.length > 0) {
        if (!taskIds.includes(task.SnId)) {
          // check for attachments first
          if ('attachments' in task.FormData) {
            // delete those attachments
            for (const attachment of task.FormData.attachments) {
              await deleteDataInIDB('SNAttachment', attachment.snId)
            }
          }
          // delete the form from idb
          await deleteDataInIDB('Form', task.Id)
          Store.$app.$azureLogger.writeLogItem('activity', {
            Application: 'Task Data Worker',
            FeatureName: 'syncTask',
            ActivityType: Store.getters.loggingActivityType.deleted,
            AdditionalDetails: `${task.SnId} was deleted from IndexedDB`,
          })
        }
      } else if (taskIds && taskIds.length === 0) {
        await DB.Form.where('FormIsDirty').equals('false').delete()
      }
    }
    // turn off spinner semaphore
    Store.dispatch('updateTaskSyncExecuting', false)
  } catch (err) {
    const errDescription = `A problem occured during task sync retrieval for EmployeeId: ${employeeId} - `

    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'taskData web worker',
      FeatureName: 'Syncing tasks to ServiceNow',
      ErrorCode: errDescription + err.name,
      ErrorDescription: 'Failed to get task data.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })
    // turn off spinner semaphore
    Store.dispatch('updateTaskSyncExecuting', false)
  }
}

// Supporting methods from here down *************************************************************
// ***********************************************************************************************
async function insertUpdateIDBTaskRecord (taskData, employeeId) {
  try {
    // place the Task's Form Data into indexedDb
    const iDBRecord = {}
    // recreating Md5 since it is not returned from SN
    const Md5 = await md5(JSON.stringify(taskData))
    iDBRecord.Id = taskData.fvId
    iDBRecord.EmployeeId = employeeId.trim()
    iDBRecord.SnId = taskData.taskId
    iDBRecord.SnLink = taskData.snLink
    // these are used in the context of troubleshooting, if we want to have them.  In order to have them the data needs to exist in the data sent to and returned by SN
    // iDBRecord.CreatedOn = I was using => formData.createdDate, but it no longer exists.  Now what should be used?
    // iDBRecord.SentOn = I was using => formData.updated, but it no longer exists.  Now what should be used?
    // iDBRecord.ModifiedOn = I guess I could use the more recent date value between formData. saveDate and completedDate
    iDBRecord.Submitted = 1
    iDBRecord.Status = taskData.status
    iDBRecord.FormData = taskData
    iDBRecord.AssignedOn = taskData.taskDate
    iDBRecord.TaskDefinitionId = taskData.taskDefinition
    iDBRecord.FormIsDirty = 'false'
    iDBRecord.Md5 = Md5
    iDBRecord.ModifiedOn = taskData.dateSaved ? taskData.dateSaved : taskData.taskDate
    iDBRecord.FormVersion = taskData.version ? taskData.version : 2 // 2 - is the equivilent to the first version of the form in FV
    await DB.Form.put(iDBRecord)
  } catch (err) {
    const errDescription = `A problem occured during task sync retrieval for EmployeeId: ${employeeId} - `

    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'taskData web worker',
      FeatureName: insertUpdateIDBTaskRecord(),
      ErrorCode: errDescription + err.name,
      ErrorDescription: 'Failed to write task data to IndexedDB.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })
  }
}

async function insertUpdateIDBAttachmentRecord (attachmentData, snId, fvId) {
  try {
    if (attachmentData) {
      // check to see if the form already has attachments in IDB, if so, then replace them, otherwise add them
      const idbAttachmentRecord = await DB.Attachment.where({ SnId: snId }).first()

      // place the Task's Form Data into indexedDb
      const iDBRecord = {}
      // recreating Md5 since it is not returned from SN
      const Md5 = await md5(JSON.stringify(attachmentData))
      iDBRecord.Id = idbAttachmentRecord ? idbAttachmentRecord.Id : uuidv4() // doing this because we need an id property regardless for IDB
      iDBRecord.SnId = snId
      iDBRecord.CreatedOn = attachmentData.timeStamp
      iDBRecord.SentOn = attachmentData.timeStamp // this is really the datetime SN received it
      iDBRecord.Submitted = 1
      iDBRecord.AttachmentData = attachmentData.imageData
      iDBRecord.TaskDefinitionId = attachmentData.taskDefinition
      iDBRecord.Md5 = Md5
      iDBRecord.Format = attachmentData.contentType
      iDBRecord.ParentId = fvId
      iDBRecord.Section = attachmentData.section

      await DB.Attachment.put(iDBRecord)
    }
  } catch (err) {
    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'taskData web worker',
      FeatureName: 'insertUpdateIDBAttachmentRecord()',
      ErrorCode: err.name,
      ErrorDescription: 'Failed to write attachment task data to IndexedDB.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })
  }
}

// Get the tasks/forms that need to be sent to ServiceNow for the first time
async function getUnsubmittedFromIDB (objectType) {
  let results = []
  if (objectType === 'Form') {
    results = await DB.Form.where('SnId').equals('').toArray()
  } else if (objectType === 'Attachment') {
    results = await DB.Attachment.where('SnId').equals('').toArray()
    Store.dispatch('updateUnsubmittedAttachments', results)
  }
  return results
}

async function getUpdatedFormsFromIDB () {
  // only get those that have at one time already been submitted to ServiceNow
  let results = await DB.Form.where({ FormIsDirty: 'true' }).toArray()
  Store.dispatch('updateUnsubmittedForms', results)
  results = results.filter((data) => { return data.SnId !== '' })
  return results
}

async function getDeletedFromIDB (objectType) {
  let results = []
  if (objectType === 'Form') {
    results = await DB.Form.where({ Submitted: 2 }).toArray()
    Store.dispatch('updateDeletedForms', results)
  } else if (objectType === 'Attachment') {
    results = await DB.Attachment.where({ Submitted: 2 }).toArray()
    Store.dispatch('updateDeletedAttachments', results)
  }
  return results
}

async function getTaskFormDataFromSN (reasonCode, requestData, objectType) {
  try {
    const payload = createPayload(reasonCode, requestData, null, objectType)
    const URL = createUrl(reasonCode, null, objectType)

    const response = await Axios.post(URL,
      payload,
      {
        headers: await getHeaders(),
      },
    )
    return response.data.result
  } catch (error) {
    writeToLog('error',
      'TaskData Web Worker',
      'getTaskFormDataFromSN()',
      'Axios Error',
      'Failed to get form data from ServiceNow.',
      `Message: ${typeof error.message === 'object' && error.message !== null
                ? JSON.stringify(error.message)
                : error.message}. Stack: ${error.stack}`,
    )
  }
}

async function getAttachmentFromSN (reasonCode, requestData, objectType) {
  try {
    const payload = createPayload(reasonCode, requestData, null, objectType)
    const URL = createUrl(reasonCode, null, objectType)

    const response = await Axios.get(URL + payload,
      {
        headers: await getHeaders(),
      },
    )
    return response.data.result
  } catch (error) {
    writeToLog('error',
      'TaskData Web Worker',
      'getTaskFormDataFromSN()',
      'Axios Error',
      'Failed to get form data from ServiceNow.',
      `Message: ${typeof error.message === 'object' && error.message !== null
                ? JSON.stringify(error.message)
                : error.message}. Stack: ${error.stack}`,
    )
  }
}

async function sendTaskFormDataToSN (reasonCode, formData, taskDefinitionId, objectType, parentSnId) {
  try {
    let response = null
    const payload = createPayload(reasonCode, formData, taskDefinitionId, objectType, parentSnId)
    const URL = createUrl(reasonCode, taskDefinitionId, objectType)

    if (reasonCode !== 'delete') {
      response = await Axios.put(URL,
        payload,
        {
          headers: await getHeaders(),
        },
      )
      if (objectType === 'Form') {
        return response.data.result.taskId
      } else {
        return response.data.result.snId
      }
    } else {
      response = await Axios.delete(URL + payload,
      {
        headers: await getHeaders(),
      },
      )
      return response.data.result.taskId
    }
  } catch (error) {
    writeToLog('error',
      'TaskData Web Worker',
      'sendTaskFormDataToSN()',
      'Axios Error',
      'Failed to send form data to ServiceNow.',
      `Message: ${typeof error.message === 'object' && error.message !== null
                ? JSON.stringify(error.message)
                : error.message}. Stack: ${error.stack}`,
    )
  }
}

async function updateFormDataInIDB (id, snId, objectType, status) {
  const submitted = 1

  if (objectType === 'Form') {
    await DB.Form.update(id, { SentOn: new Date().toLocaleString(), SnId: snId, Status: status, Submitted: submitted, FormIsDirty: 'false' })
  } else if (objectType === 'Attachment') {
    await DB.Attachment.update(id, { SentOn: new Date().toLocaleString(), SnId: snId, Submitted: 1 })
  }
}

async function deleteDataInIDB (objectType, id) {
  if (objectType === 'Form') {
    await DB.Form.where('Id').equals(id).delete()
  } else if (objectType === 'SNAttachment') {
    await DB.Attachment.where('SnId').equals(id).delete()
  } else {
    await DB.Attachment.where('Id').equals(id).delete()
  }
}

function createUrl (reasonCode, taskDefinitionId, objectType) {
  let url = ''

  if (taskDefinitionId) {
    // TODO - Add additional Task Definitions as the forms become available
    switch (taskDefinitionId) {
      case TASKDEFINITIONS.DailySafetyBriefing: // Daily Safety and Job Briefing Report
        if (objectType === 'Form') {
          if (reasonCode === 'create' || reasonCode === 'update') {
            url = `${Store.getters.serviceNowBaseURL}fmt/PutDailySafety`
          } else if (reasonCode === 'delete') {
            url = `${Store.getters.serviceNowBaseURL}fmt/DeleteTasks?taskId=`
          }
        } else if (objectType === 'Attachment') {
          if (reasonCode === 'create') {
            url = `${Store.getters.serviceNowBaseURL}fmt/PutAttachmentToRecord`
          } else if (reasonCode === 'delete') {
            url = `${Store.getters.serviceNowBaseURL}fmt/DeleteAttachment`
          }
        }
        break
      case TASKDEFINITIONS.DailyCounts: // Daily Counts Report
        if (objectType === 'Form') {
          if (reasonCode === 'create' || reasonCode === 'update') {
            url = `${Store.getters.serviceNowBaseURL}fmt/PutDailyCount`
          } else if (reasonCode === 'delete') {
            url = `${Store.getters.serviceNowBaseURL}fmt/DeleteTasks?taskId=`
          }
        }
        break
      case TASKDEFINITIONS.DailyDOT: // Daily Counts Report
        if (objectType === 'Form') {
          if (reasonCode === 'create' || reasonCode === 'update') {
            url = `${Store.getters.serviceNowBaseURL}fmt/PutDailyDOT`
          } else if (reasonCode === 'delete') {
            url = `${Store.getters.serviceNowBaseURL}fmt/DeleteTasks?taskId=`
          }
        }
        break
      case TASKDEFINITIONS.SafetyQualification:
        if (objectType === 'Form') {
          if (reasonCode === 'create' || reasonCode === 'update') {
            url = `${Store.getters.serviceNowBaseURL}fmt/PutSafetyQualifications`
          } else if (reasonCode === 'delete') {
            url = `${Store.getters.serviceNowBaseURL}fmt/DeleteSafetyQualification?taskId=`
          }
        }
        break
      case TASKDEFINITIONS.GroundWireDOP:
        if (objectType === 'Form') {
          if (reasonCode === 'create' || reasonCode === 'update') {
            url = `${Store.getters.serviceNowBaseURL}fmt/PutGroundWireDOP`
          } else if (reasonCode === 'delete') {
            url = `${Store.getters.serviceNowBaseURL}fmt/DeleteGroundWireDOP?taskId=`
          }
        }
        break
      default:
        // console.log('Unknown Task Form used.')
    }
  } else {
    switch (objectType) {
      case 'Form':
        if (reasonCode === 'get') {
          url = `${Store.getters.serviceNowBaseURL}fmt/GetTasksByDate`
        }
        break
      case 'Attachment':
      if (reasonCode === 'get') {
        url = `${Store.getters.serviceNowBaseURL}fmt/GetAttachment`
      }
      break
    }
  }

  return url
}

function createPayload (reasonCode, data, taskDefinitionId, objectType, parentSnId) {
  let result = {}
  if (objectType === 'Form') {
    switch (reasonCode) {
      case 'create':
      case 'update':
        result = {
          taskId: data.SnId,
          validation: data.Md5,
          formData: data.FormData,
        }
        break
      case 'delete':
      case 'get':
        result = data
        break
    }
  } else if (objectType === 'Attachment') {
    switch (reasonCode) {
      case 'create':
        result = {
          validate: data.Md5,
          attachment: {
            taskId: parentSnId,
            taskDefinition: taskDefinitionId,
            section: data.Section,
            imageData: data.AttachmentData,
            contentType: data.Format,
          },
        }
        break
      case 'delete':
        result = `?taskDefinition=${taskDefinitionId}&snId=${data}`
        break
      case 'get':
        result = `?snId=${data}`
        break
    }
  }

  return result
}

// Determine what date range of tasks should be stored in IndexedDb
function getBeginAndEndDateRange () {
  // begin with today's date
  const endDate = DateTime.local().toFormat('yyyy-MM-dd')
  const startDate = DateTime.local().minus({ days: 21 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')

  return { startDate: startDate, endDate: endDate }
}

// Remove any tasks in IndexedDb prior to the start date
async function cleanupIDB (startDate) {
  const startDateTime = DateTime.fromFormat(startDate, 'yyyy-MM-dd').startOf('day').ts
  const allForms = await DB.Form.toArray()

  // clean up IDB, remove those tasks outside the prior 2 weeks.
  // filter out New EE Checklists because they have special storage requirements
  const notInRangeForms = allForms.filter(form => form.FormData.fvTaskId !== 9).filter(form => {
    const taskDate = DateTime.fromFormat(form.FormData.taskDate.split(' ')[0], 'yyyy-MM-dd').startOf('day').ts
    return taskDate < startDateTime
  
  })
  const taskFVIds = notInRangeForms.map(p => p.Id)
  for (const fvId of taskFVIds) {
    await DB.Form.where('Id').equals(fvId).delete()
  }

  // Delete completed EE Checklist with a dateSaved prior to the start date
  // Do not delete ANY in_progress EE Checklists
  const notInRangeEEChecklists = allForms.filter(form => form.FormData.fvTaskId === 9 && form.FormData.status === 'completed').filter(form => {
    const dateSaved = DateTime.fromFormat(form.FormData.dateSaved.split(' ')[0], 'yyyy-MM-dd').startOf('day').ts
    return dateSaved < startDateTime
  })
  
  const eeChecklistFVIds = notInRangeEEChecklists.map(p => p.Id)
  for (const fvId of eeChecklistFVIds) {
    await DB.Form.where('Id').equals(fvId).delete()
  }
}

async function getHeaders () {
  const snak = await SnakManager.getSNAK(Store.$app.$storage)

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${snak}`,
  }
}

function writeToLog (logType = 'error', application = 'TaskData Web Worker',
                     featureName = '', errorCode = '', errorDescription = '',
                     addtionalDetails = '', activityType = '') {
  if (logType === 'error') {
    Store.$app.$azureLogger.writeLogItem(logType, {
      Application: application,
      FeatureName: featureName,
      ErrorCode: errorCode,
      ErrorDescription: errorDescription,
      AdditionalDetails: addtionalDetails,
    })
  } else {
    this.$azureLogger.writeLogItem(logType, {
      Application: application,
      FeatureName: featureName,
      ActivityType: activityType,
      AdditionalDetails: addtionalDetails,
    })
  }
}

const TASKDEFINITIONS = {
  DailySafetyBriefing: '788ab989dbdcff080d9ef12cbf96196f',
  DailyCounts: 'e0277576dba28c902a0278f9bf961918',
  DailyDOT: '3f98d19e1b129950fde1968f034bcb4b',
  SafetyQualification: '9d383f661bc1f510fde1968f034bcb2f',
  GroundWireDOP: '2b219ce2473ddad8ff7563f1d16d43e8'
}
